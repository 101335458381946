<template>
  <div class="box" id="viewdocs">
    <!-- <label>Press to view documents</label><input type="button" @click="getDocList()" value="View Docs" /> -->
    <h2 v-if="doclist" class="block">Your {{ year }} Tax Year Files:</h2>
    <ul v-if="doclist">
        <li class="has-text-centered level-item" v-for="doc in doclist" v-bind:key="doc.doc_id">
            {{ doc.remark }}
            <input class="button" type="button" id={{doc.doc_id}} @click="getFile(doc.link,doc.remark)" value="Download" />
        </li>
    </ul>
    <div v-else class="notification">
        No files are available for Tax Year {{ year }}.
    </div>
  </div>
</template>

<script>
import axios from 'axios';

// const GET_URL = "http://localhost/taxdocphp/fileDownload.php";
const GET_URL = "https://taxdocphp.brontecollege.ca/fileDownload.php";

export default {
    data() {
        return {
            doclist: null
        }
    },
    props: {
        year: {
            type: String,
            required: true
        }
    },
    watch: {
        year: function() {
            console.log(`Watch Year:${this.year}`);
            this.doclist = null;
            this.getDocList();
        }
    },
    components: {

    },
    mounted() {
        if(this.$store.state.accessToken != null) {
             this.getDocList();
        }
    },
    methods: {
        getFile(docRequested,docName) {
            //console.log(docRequested);
            let self = this;
            axios.get(GET_URL, {
                params: {
                    staffid: this.$store.state.staffID,
                    year: self.year,
                    action: 'Download',
                    doclink: docRequested
                },
                responseType: 'blob'
            })
            .then(function (response) {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                     var fileLink = document.createElement('a');

                     fileLink.href = fileURL;
                     fileLink.setAttribute('download', docName);
                     document.body.appendChild(fileLink);

                     fileLink.click(); 
                //console.log(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        getDocList() {
            console.log(`Year:${this.year}`);
            let self = this;
            axios.get(GET_URL, {
                params: {
                    staffid: this.$store.state.staffID,
                    year: self.year,
                    action: 'View'
                }
            })
            .then(function (response) {
                // Should handle the response
                //console.log(response);
                if (response.data.status == 0) {
                    self.doclist = response.data.response;
                } else {
                    self.doclist = null;
                }
                // console.log(response.data.status);
                // console.log(response.data.message);
                // console.log(response.data.response);

            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }
}
</script>

<style>

</style>