<template>
  <div id="viewdocs">
    <h1 class="title">View Your Tax Documents</h1>
    <select-year v-model="thisYear" />
    <view-documents-component :year="thisYear" />
  </div>
</template>

<script>
import ViewDocumentsComponent from "@/components/ViewDocumentsComponent.vue"
import SelectYear from '../components/SelectYear.vue';


export default {
  data() {
    return {
      thisYear: '2022'
    }
  },
  props: {
    // year: {
    //             type: String,
    //             required: true
    //         }
  },
  components: {
    ViewDocumentsComponent, SelectYear
  }
};
</script>

<style>

</style>